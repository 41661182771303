/*
 * Skin: Nevada
 * -----------
 */
@import "node_modules/bootstrap-less/bootstrap/mixins.less";
@import "node_modules/bootstrap-less/bootstrap/variables.less";
@import "../variables.less";
@import "../mixins.less";
/*@import "../boxes.less";*/

/* General Links */
a {
  color: @link-color;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: @link-hover-color;
}

/* skin-nevada navbar */
.skin-nevada {
  //Navbar & Logo
  .main-header {
    .box-shadow(0px 1px 1px rgba(0, 0, 0, 0.05));
    .navbar-toggle {
      color: #333;
    }
    .navbar-brand {
      color: #333;
      padding: 0 15px;
      .logo-lg {
        background: url('../img/logo.png') center center no-repeat;
        background-size: 120px;
        height: 50px;
        width: 200px;
        display: inline-block;
      }
    }
    .navbar {
      .navbar-variant(#fff; #333; #999; #fff);
      > .sidebar-toggle {
        color: #333;
      }
      .navbar-custom-menu .navbar-nav,
      .navbar-right {
        > li {
          > a {
            border-right-width: 0;
          }
        }
      }
    }
    > .logo {
      .logo-variant(#fff; #333);
      @media (max-width: @screen-header-collapse) {
        .logo-variant(#222; #fff);
        border-right: none;
      }
      .logo-lg {
        background: url('../img/logo.png') center center no-repeat;
        background-size: 120px;
        height: 50px;
      }
    }

    li.user-header {
      background-color: #222;
    }
  }

  //Content Header
  .content-header {
    background: transparent;
    box-shadow: none;
  }
  //Create the sidebar skin
  .skin-dark-sidebar(#fff);

  .pace {
    .pace-progress {
      background: #222;
    }
    .pace-activity {
      border-top-color: #222;
      border-left-color: #222;
    }
  }
}
