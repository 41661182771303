/*
 * Skin: Nevada
 * -----------
 */
/*@import "../boxes.less";*/
/* General Links */
a {
  color: #3c8dbc;
}
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #72afd2;
}
/* skin-nevada navbar */
.skin-nevada .main-header {
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.skin-nevada .main-header .navbar-toggle {
  color: #333;
}
.skin-nevada .main-header .navbar-brand {
  color: #333;
  padding: 0 15px;
}
.skin-nevada .main-header .navbar-brand .logo-lg {
  background: url('../img/logo.png') center center no-repeat;
  background-size: 120px;
  height: 50px;
  width: 200px;
  display: inline-block;
}
.skin-nevada .main-header .navbar {
  background-color: #fff;
}
.skin-nevada .main-header .navbar .nav > li > a {
  color: #333;
}
.skin-nevada .main-header .navbar .nav > li > a:hover,
.skin-nevada .main-header .navbar .nav > li > a:active,
.skin-nevada .main-header .navbar .nav > li > a:focus,
.skin-nevada .main-header .navbar .nav .open > a,
.skin-nevada .main-header .navbar .nav .open > a:hover,
.skin-nevada .main-header .navbar .nav .open > a:focus,
.skin-nevada .main-header .navbar .nav > .active > a {
  background: #fff;
  color: #999;
}
.skin-nevada .main-header .navbar .sidebar-toggle {
  color: #333;
}
.skin-nevada .main-header .navbar .sidebar-toggle:hover {
  color: #999;
  background: #fff;
}
.skin-nevada .main-header .navbar > .sidebar-toggle {
  color: #333;
}
.skin-nevada .main-header .navbar .navbar-custom-menu .navbar-nav > li > a,
.skin-nevada .main-header .navbar .navbar-right > li > a {
  border-right-width: 0;
}
.skin-nevada .main-header > .logo {
  background-color: #fff;
  color: #333;
  border-bottom: 0 solid transparent;
}
.skin-nevada .main-header > .logo:hover {
  background-color: #fcfcfc;
}
@media (max-width: 767px) {
  .skin-nevada .main-header > .logo {
    background-color: #222;
    color: #fff;
    border-bottom: 0 solid transparent;
    border-right: none;
  }
  .skin-nevada .main-header > .logo:hover {
    background-color: #1f1f1f;
  }
}
.skin-nevada .main-header > .logo .logo-lg {
  background: url('../img/logo.png') center center no-repeat;
  background-size: 120px;
  height: 50px;
}
.skin-nevada .main-header li.user-header {
  background-color: #222;
}
.skin-nevada .content-header {
  background: transparent;
  box-shadow: none;
}
.skin-nevada .wrapper,
.skin-nevada .main-sidebar,
.skin-nevada .left-side {
  background-color: #222d32;
}
.skin-nevada .user-panel > .info,
.skin-nevada .user-panel > .info > a {
  color: #fff;
}
.skin-nevada .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226;
}
.skin-nevada .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
.skin-nevada .sidebar-menu > li:hover > a,
.skin-nevada .sidebar-menu > li.active > a {
  color: #fff;
  background: #1e282c;
  border-left-color: #fff;
}
.skin-nevada .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41;
}
.skin-nevada .sidebar a {
  color: #b8c7ce;
}
.skin-nevada .sidebar a:hover {
  text-decoration: none;
}
.skin-nevada .treeview-menu > li > a {
  color: #8aa4af;
}
.skin-nevada .treeview-menu > li.active > a,
.skin-nevada .treeview-menu > li > a:hover {
  color: #fff;
}
.skin-nevada .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px;
}
.skin-nevada .sidebar-form input[type="text"],
.skin-nevada .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
}
.skin-nevada .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-nevada .sidebar-form input[type="text"]:focus,
.skin-nevada .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-nevada .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-nevada .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-nevada .pace .pace-progress {
  background: #222;
}
.skin-nevada .pace .pace-activity {
  border-top-color: #222;
  border-left-color: #222;
}
